var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.translates && _vm.translates[_vm.langUrl])?_c('div',{staticClass:"authorization"},[(_vm.translates && _vm.translates[_vm.langUrl])?_c('div',{staticClass:"authorization__form"},[_c('div',{staticClass:"authorization__form__top"},[(_vm.isDesktop)?_c('div',{staticClass:"authorization__form__top__logo"}):_vm._e(),_c('div',{staticClass:"authorization__form__top__title"},[_vm._v(_vm._s(_vm.pageTitle))])]),(_vm.step === 'phoneTextField')?[_c('div',{staticClass:"authorization__form__input"},[_c('ValidationObserver',{ref:"validator_1",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BasePhoneNumberInput',{attrs:{"error-messages":errors[0]},on:{"update":function($event){return _vm.number($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,false,775119111)})],1),_c('BaseButton',{staticClass:"button-fill",staticStyle:{"width":"100%"},on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_next[_vm.lang]))])],1)]:_vm._e(),(_vm.step === 'phoneConfirm')?[_c('div',{staticClass:"authorization__form__text"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].nuber_part_1[_vm.lang])+" "+_vm._s(_vm.phoneWithCode)+" "+_vm._s(_vm.translates[_vm.langUrl].nuber_part_2[_vm.lang]))]),_c('div',{staticClass:"authorization__form__input"},[_c('ValidationObserver',{ref:"validator_2",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required|code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"pattern":"\\d*","label":_vm.translates[_vm.langUrl].label_code[_vm.lang],"counter":"4","error-messages":errors[0],"autocomplete":"off"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmPhone.apply(null, arguments)}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,false,623050841)}),(_vm.secondsLeft)?_c('div',{staticClass:"authorization__form__send-code"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_codeRepeat_part_1[_vm.lang])+" "),_c('span',{staticClass:"authorization__form__send-code__timer"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_codeRepeat_part_2[_vm.lang])+" "+_vm._s(_vm.prettyCounter))])]):_c('div',{staticClass:"authorization__form__send-code_active",on:{"click":_vm.phoneRepeatSubmit}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_codeRepeat_part_1[_vm.lang]))])],1),_c('BaseButton',{staticClass:"button-fill",staticStyle:{"width":"100%"},on:{"click":_vm.confirmPhone}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_next[_vm.lang]))])],1)]:_vm._e(),(_vm.step === 'emailTextField')?[_c('div',{staticClass:"authorization__form__input"},[_c('ValidationObserver',{ref:"validator_3",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"label":_vm.translates[_vm.langUrl].label_email[_vm.lang],"error-messages":errors[0]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveEmail.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,false,3349104323)})],1),_c('BaseButton',{staticClass:"button-fill blogger-registration__form__button",on:{"click":_vm.saveEmail}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_next[_vm.lang]))])],1)]:_vm._e(),(_vm.step === 'emailConfirm')?[_c('div',{staticClass:"authorization__form__text"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].email_part_1[_vm.lang])+" "+_vm._s(_vm.email)+" "+_vm._s(_vm.translates[_vm.langUrl].email_part_2[_vm.lang]))]),_c('div',{staticClass:"authorization__form__input"},[_c('ValidationObserver',{ref:"validator_4",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required|code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"pattern":"\\d*","label":_vm.translates[_vm.langUrl].label_emailCode[_vm.lang],"counter":"4","error-messages":errors[0]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmEmail.apply(null, arguments)}},model:{value:(_vm.emailCode),callback:function ($$v) {_vm.emailCode=$$v},expression:"emailCode"}})]}}],null,false,4267828118)}),(_vm.secondsLeft)?_c('div',{staticClass:"authorization__form__send-code"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_codeRepeat_part_1[_vm.lang])+" "),_c('span',{staticClass:"authorization__form__send-code__timer"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_codeRepeat_part_2[_vm.lang])+" "+_vm._s(_vm.prettyCounter))])]):_c('div',{staticClass:"authorization__form__send-code_active",on:{"click":_vm.emailRepeatSubmit}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_codeRepeat_part_1[_vm.lang]))])],1),_c('BaseButton',{staticClass:"button-fill",staticStyle:{"width":"100%"},on:{"click":_vm.confirmEmail}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_next[_vm.lang]))])],1)]:_vm._e(),(_vm.step === 'userInfo')?[_c('div',{staticClass:"authorization__form__input"},[_c('ValidationObserver',{ref:"validator_5",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"label":_vm.translates[_vm.langUrl].firstname[_vm.lang],"error-messages":errors[0]},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})]}}],null,false,3668569483)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('LabelTextField',{attrs:{"label":_vm.translates[_vm.langUrl].lastname[_vm.lang],"error-messages":errors[0]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveUser.apply(null, arguments)}},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})]}}],null,false,2274850087)})],1),_c('BaseButton',{staticClass:"button-fill blogger-registration__form__button",on:{"click":_vm.saveUser}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].button_next[_vm.lang]))])],1)]:_vm._e()],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }